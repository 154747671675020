module.exports = [{
      plugin: require('/Users/bartekwidlarz/projects/okelm.github.io/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"linkImagesToOriginal":false,"quality":100,"sizeByPixelDensity":true},
    },{
      plugin: require('/Users/bartekwidlarz/projects/okelm.github.io/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/bartekwidlarz/projects/okelm.github.io/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-138237728-1","anonymize":true,"cookieExpires":0},
    },{
      plugin: require('/Users/bartekwidlarz/projects/okelm.github.io/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/bartekwidlarz/projects/okelm.github.io/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/Users/bartekwidlarz/projects/okelm.github.io/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/bartekwidlarz/projects/okelm.github.io/node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/bartekwidlarz/projects/okelm.github.io/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
